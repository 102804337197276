import React, { useEffect } from 'react';

export default function CONTACT_CMPNT() {
  useEffect(() => {
    const appointy = () => {
      const ifrm = document.getElementById('appointy-iframe');
      window.addEventListener('message', (e) => {
        const d = e.data || {};
        if (d.type === 'height') {
          ifrm.style.height = d.data + 'px';
        }
        if (d.type === 'scroll') {
          ifrm.scrollIntoView();
        }
      });
    };
    appointy();
  }, []);

  return (
    <div className="container">
      <p>Pour plus d'informations , vous pouvez me contacter au <b>07 66 64 04 28</b> ou m’envoyer un mail à contact@bubblemome.fr</p>
      <p>Vous pouvez aussi éffectuer une réservation ci-dessous :</p>
      <iframe
        id="appointy-iframe"
        className="no-border"
        src="https://booking.appointy.com/Bubblemome?isgadget=1&autoheight=1"
        scrolling="no"
        width="100%"
        frameBorder="0"
      ></iframe>
    </div>
  );
}