import DBL from "../assets/signification DBL.png"
import DBL2 from "../assets/bebe pleur.jpg"
import DBL3 from "../assets/bebe calme.jpg"

export default function DBL_CMPNT() {
    return(
        <div className="container">
            <h1>Le Dunstan Baby Language ou l’art d’écouter et d’observer le bébé pour
            discerner ses besoins physiologiques</h1>
            <img className="float__right" src={DBL}></img>
            <p>
            L’arrivée d’un bébé est un bouleversement, que celui-ci soit familial ou
            personnel, ce bouleversement entraîne de nombreuses questions, vais-je le
            comprendre ? et s’il pleure et que je ne le comprends pas, que va-t-il se
            passer ? aurais-je la force de supporter ses pleurs ?<br></br>
            Car oui, un bébé cela pleure, c’est son langage naturel et comprendre ce
            langage est important.<br></br>
            Quel que soit l’endroit de la terre où bébé naît, il exprime ses besoins
            physiologiques à travers ses pleurs, ses cris, ses gémissements ; ainsi, les
            pleurs de faim, de sommeil, de rot, de douleurs intestinales ou d'inconfort
            sont les mêmes, d'un bout à l'autre de la planète
            </p>
            <p>
            Ainsi que vous soyez parents, futurs parents ou professionnels de la Petite
            Enfance, l'atelier DBL vous permet d'apprendre à identifier ces 5 pleurs et
            prendre confiance en la capacité de l'enfant à communiquer ses besoins.
            </p>
            <p>
            Le Dunstan Baby Language a de nombreux bienfaits :
            </p>
            <p>
            Pour le bébé :
            </p>
            <img className="float__right" src={DBL2}></img>
            <ul>
                <li>Un bébé plus calme</li>
                <li>Un sommeil plus apaisé</li>
                <li>Un bébé sécure</li>
                <li>Baisse du nombre de bébés secoués</li>
            </ul>
            <p>
                Pour les parents et professionnels :
            </p>
            <ul>
                <li>Valorisation des compétences parentales</li>
                <li>Compétences professionnelles renforcées</li>
                <li>Moins de stress</li>
                <li>Diminution de la dépression post partum</li>
            </ul>
            <img className="float__left" src={DBL3}></img>
            <p>
                Pour qui ?
            </p>
            <p>
                Parents, futures parents, professionnels de la Petite Enfance
            </p>
            <p>
                Comment ?
            </p>
            <p>
            Au cours d’une session de 2 ateliers je vous transmettrai l’art de discerner
            les 5 pleurs liés aux besoins physiologiques du tout-petit
            </p>
            <h2>Professionnels</h2>
            <p>
            Si à votre tour vous souhaitez devenir Instructeur en Dunstan Baby
            Language et transmettre les clés de ce merveilleux outil aux parents, futurs
            parents et professionnels de la Petite Enfance, contactez moi au 07 66 64 04
            28 ou contact@bubblemome.fr je vous donnerai tous les détails liés à la
            formation d’Instructeur
            </p>
            <h2>Déroulé d'un atelier DBL :</h2>
            <p>
                Au cours de ces 2 ateliers, c’est avec bonheur que je vous transmettrait :
            </p>
            <ul>
                <li>L'histoire du DBL</li>
                <li>Les 5 pleurs physiologiques</li>
                <li>La routine</li>
                <li>Les pistes de réfelexions</li>
                <li>Les fiches récapitulatives aux 5 pleurs physiologiques</li>
            </ul>
            <h2>Tarif :</h2>
            <p>
            Une session de 2 ateliers d’1heure à 1heure 30<br></br>
            Accompagnement des familles durant 1 mois à compter du dernier atelier ou de la date de naissance de bébé, via Messenger, mail ou WhatsApp<br></br>
            60€
            </p>
        </div>
    )
}