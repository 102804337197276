import BB1 from "../assets/portage bb pour site 1.jpg"
import BB2 from "../assets/portage bb pour site 2.jpg"

export default function Portage_CMPNT() {
    return (
        <div className="container">
            <div>
                <div>
                    <h1>Le portage physiologique</h1>
                    <h2>Le portage physiologique ou l’art de porter un bébé dans le respect de la posture corporelle.</h2>
                    <img className="float__right" src={BB1} alt="Un bébé"></img>
                    <p>
                        Le portage est une pratique ancestrale, très répandue à travers le monde, chaque continent
                        dispose de moyens spécifiques pour porter. En Afrique, ce sont des pagnes, en Amérique du
                        Nord, c’est un amauti (manteau de portage inuit) en Amérique du sud ou centrale, ce sont des
                        écharpes ou pans de laines tissés et colorés en Océanie, ce sont des filets, en Asie, ce sont des
                        paniers en osier ou des Mei Tei.
                    </p>
                    <p>
                        Ainsi, « en portant votre bébé, vous lui transmettez ce qu’aucune chambre d’enfant installée avec
                        amour, aucun berceau ni aucune poussette ultra confortable ne peuvent transmettre : le
                        sentiment de bien-être, de sécurité, de chaleur, le mouvement, la vie. » Félix Stauffer, biologiste.
                    </p>
                </div>
            </div>
            <div>
                <div>
                    <h2>Les bienfaits du portage</h2>
                    <h3>Pour le bébé, dès la naissance</h3>
                    <ul>
                        <li>Continuité naturelle avec la grossesse, notion de continuum</li>
                        <li>Permet au bébé d’apprivoiser son nouvel environnement</li>
                        <li>Répond au besoin de proximité intense</li>
                        <li>Contribue à la maturation du bébé à terme, comme du bébé prématuré</li>
                        <li>Procure un sentiment de sécurité, de bien-être</li>
                        <li>Impact positif sur la physiologie</li>
                        <li>Prévient la dysplasie de la hanche et la plagiocéphalie</li>
                        <li>Développe le lien d’attachement entre le bébé et le parent</li>
                        <li>Favorise l’allaitement</li>
                        <li>Facilite la digestion, diminue les problèmes de reflux, de coliques</li>
                        <li>Favorise le sommeil</li>
                        <li>Favorise le développement moteur</li>
                    </ul>
                </div>
            </div>
            <h3>Pour les parents</h3>
            <img className="float__right" src={BB2} alt="Un autre bébé"></img>
            <ul>
                <li>Plaisir partagé</li>
                <li>Renforce le sentiment de compétence parentale</li>
                <li>Liberté de mouvement et d’action</li>
            </ul>
            <h2>Déroulé des ateliers</h2>
            <p>
                Séance d’information : 1h00
            </p>
            <p>
                Présentation du concept, des bienfaits et des différents outils de portage
            </p>
            <h3>Pour qui :</h3>
            <p>
                Futurs parents et néo parents
            </p>
            <p>
                Atelier de base : 1h30
            </p>
            <p>
                Théorie, respect de la physiologie du bébé et du porteur, consignes de sécurité, présentation des
                différents systèmes de portage et pratique (ventral et côté).
            </p>
            <h2>Tarif :</h2>
            <p>
                Atelier de base (théorie + portage ventral et côté) : 35€
            </p>
        </div>
    )
}