import bb1 from "../assets/bb signe pour site 3.jpg"
import bb2 from "../assets/bb signe pour site.jpg"
import bb3 from "../assets/bb signe pour site 2.png"
export default function CSAP_CMPNT() {
    return (
        <div className="container">
            <h1>La Communication Signée Accompagnée de la Parole</h1>
            <img className="float__right" src={bb1}></img>
            <p>
                Quelle joie, quel plaisir, de pouvoir communiquer avec bébé avant qu'il n'ait acquis le
                langage !
            </p>
            <p>
                L’atelier de communication signée accompagnée de la parole, est un concept 
                parfaitement adapté aux bébés entendants.
            </p>
            <p>
                Lors de ces ateliers, on ajoute à la communication usuelle, des signes issus de la Langue
                des Signes Française pour mettre en éclairage les mots importants du quotidien de bébé.
                L'utilisation de la Langue des Signes Française, rend ainsi possible un code commun pour
                tous ceux qui pratiquent cette forme de communication. C'est en fait, un fabuleux outil
                pour mieux communiquer avec les tout-petits qui n'ont pas encore acquis le langage.
            </p>
            <img className="float__left" src={bb2}></img>
            <p>
                Dès la naissance, le tout-petit comprend ce qu'on lui dit, mais, il faudra attendre
                plusieurs mois pour qu'il puisse parler, et être compris ; en attendant, le tout-petit s'exprime
                avec son corps, et tente de signifier à son entourage ses besoins, ses émotions, en cas
                d'incompréhension, cela génère de la frustration chez le tout-petit comme chez l'adulte,
                le tout-petit la manifeste par des cris, des pleurs...De son côté l'adulte peut éprouver du
                désarroi face à ces comportements ne sachant qu'y répondre.
            </p>
            <p>
                Ainsi, la communication signée accompagnée de la parole permet de réduire les pleurs,
                les frustrations, d'acquérir un vocabulaire considérable pour s'exprimer, et ainsi être
                mieux compris, de renforcer le lien d'attachement, d'accroître l'estime de soi.
            </p>
            <p>
                Les bienfaits de la Communication Signée Accompagnée de la Parole :
            </p>
            <img className="float__right" src={bb3}></img>
            <p>
                Pour le bébé :
            </p>
            <ul>
                <li>Permet à l’enfant de communiquer ses besoins, ses émotions, ce qui l’entoure</li>
                <li>Diminue les frustrations et pleurs dus à l’incompréhension</li>
                <li>Développe le lien</li>
                <li>Nomme ses émotions, ses sentiments, et permet de les apprivoiser</li>
                <li>Favorise la motricité et notamment la motricité fine–</li>
                <li>Développe l’observation</li>
                <li>Renforce l’estime de soi</li>
                <li>Accompagne l’enfant vers l’autonomie</li>
                <li>Encourage l’acquisition de la parole</li>
            </ul>
            <p>
                Pour les parents :
            </p>
            <ul>
                <li>Plaisir partagé</li>
                <li>Renforce le sentiment de compétence parentale</li>
                <li>Enrichit le lien d’attachement</li>
                <li>Développe l’observation</li>
            </ul>
            <h2>Déroulé des ateliers</h2>
            <p>
                1 session de 6 ateliers d’1h00 à 1h30
            </p>
            <p>
                Lors de ces ateliers, c’est avec joie que je vous transmettrai les signes relatifs aux thèmes
                de la vie quotidienne de votre enfant grâce aux histoires, aux jeux et aux comptines, ceci,
                afin de mieux vous les approprier de manière à les utiliser de suite !
            </p>
            <p>
                Un atelier dure 1 heure à 1h30 et peut accueillir 3 familles
                La famille se compose de l’enfant accompagné de 2 personnes maximum + les aînés.
            </p>
            <p>
                La participation de l’enfant est non obligatoire.
            </p>
            <p>
                L’évolution est vraiment pensée pour les 6 ateliers, c’est pourquoi votre inscription
                comprend forcément la totalité des séances.
            </p>
            <h2>Quand commencer ?</h2>
            <p>
                Aucun prérequis n’est nécessaire.
            </p>
            <p>
                Il n’y a pas d’âge pour commencer même si les bébés seront plus réceptifs à partir de 6-8
                mois.
            </p>
            <p>
                Thèmes abordés lors des 6 ateliers :
            </p>
            <ul>
                <li>La journée de Bébé</li>
                <li>L’alimentation</li>
                <li>Le bain, les vêtements</li>
                <li>Les animaux</li>
                <li>L’extérieur</li>
                <li>Les émotions</li>
            </ul>
            <p>
                A la fin de chaque atelier un support écrit récapitulant la séance vous est remis, ainsi qu'un 
                lien vers un signaire visuel de manière à bien mémoriser ce
                qui a été transmis.
            </p>
            <h2>Les tarifs :</h2>
            <p>
                Session de 6 ateliers d'1h00 : 90€ par famille (maman et co-parent+bébé)<br></br>
                Atelier exceptionnel d'1h00 : 25€ par famille (maman et co-parent+bébé)
            </p>
        </div>
    )
}