export default function TEMOIGNAGES_CMPNT() {
    return(
        <div className="container">
            <h1>Temoignages</h1>
            <p>
                Laure :
            </p>
            <p>
                <i>"J'ai suivi l'atelier Dunstan Baby Language donné par Aurélie. Cela se fait dans une ambiance chaleureuse et décontractée, à l'image d'Aurelie! Un enseignement et des moyens de qualité pour "apprendre " au mieux.
                En temps que future maman, toutes ces informations et conseils donnés durant l'atelier sont précieux et donnent confiance. C'est un 5 étoiles sont hésitation!"</i>
            </p>
            <p>
                Emilie : 
            </p>
            <p>
                <i>"Une belle rencontre !
                Grâce au Dunstan nous avons pu comprendre dès les 1ers jours de vie de Gustave ses besoins.. En ayant appris avec Aurélie les différents pleurs, nous avons su répondre plus efficacement à ses besoins. Ce qui fait que notre bébé ne pleure quasiment pas : bébé moins stressé, donc parents également plus détendus !
                JE RECOMMANDE à tout mon entourage et vais offrir en cadeau de naissance ce service qui devrait être aidé par l État pour le bien être de l enfant et des parents."</i>
            </p>
            <p>
                Juliette : 
            </p>
            <p>
                <i>"Je ne peux que recommander les ateliers proposés par Aurélie.
                Nous avons eu l'opportunité de faire l'atelier Dunstan baby language, nous y avons appris énormément d'informations sur les pleurs du bébé mais également sur la manière dont les gérer supports vidéos et documentaire à l'appui.
                Aurélie est très agréable et très accessible, elle nous met tout de suite à l'aise et en confiance dans nos compétences de futurs parents.
                Ce n'est pas pour rien que nous allons également faire avec elle l'atelier bébé signeur.Aucune hésitation à avoir, vous êtes entre de bonnes mains avec Aurélie pour vous accompagner."</i>
            </p>
            <p>
                Maximilien : 
            </p>
            <p>
                <i>Aurélie est vraiment top ! Après avoir assisté, avec ma conjointe, à un atelier pour découvrir le Dustan Baby Langage, on a suivi l'atelier complet avec elle et on en a appris beaucoup sur les pleurs des bébés. À l'approche d'un nouveau né, c'est l'indispensable pour créer une connexion avec l'enfant et le comprendre. Ça peut être aussi une super idée comme cadeau de naissance ! Vivement le prochain atelier 😃
                Je rajoute un point important : Aurélie est une personnalité en or qui prend vraiment le temps pour échanger avec vous 😉</i>
            </p>
        </div>
    )
}