import bb1 from "../assets/massage bb pour site 1.jpg"
import bb2 from "../assets/massage bb pour site 2.jpg"

export default function Massage_CMPNT() {
    return (
        <div className="container">
            <h1>Le massage Bébé</h1>
            <img className="float__right" src={bb1}></img>
            <p>
                Quel fabuleux moment de partage que celui du Massage Bébé.
            </p>
            <p>
                L’espace donné à cet art est inestimable, en effet, grâce aux gestes du toucher sain et nourrissant,
                les parents communiquent avec leur bébé, c’est un merveilleux moyen pour créer, renforcer le
                lien parent-enfant.
            </p>
            <p>
                Le massage bébé a de nombreux bienfaits
            </p>
            <p>
                Pour le bébé :
            </p>
            <ul>
                <li>Moments privilégiés</li>
                <li>Etablissement du lien d’attachement</li>
                <li>Relaxation</li>
                <li>Sommeil de qualité</li>
                <li>Régulation des états de conscience</li>
                <li>Favorise le tonus musculaire</li>
                <li>Diminution du stress</li>
                <li>Augmentation de l’ocytocine</li>
                <li>Soulagement des tensions physiques</li>
                <li>Soulagement des gaz, coliques et constipation</li>
                <li>Stimulation des capacités d’apprentissage et du langage</li>
                <li>Soulagement de la sensibilité au toucher</li>
                <li>Développement de la croissance</li>
                <li>Développement de la conscience corporelle</li>
                <li>Stimulation du fonctionnement interne du corps</li>
            </ul>
            <p>
                Pour les parents :
            </p>
            <img className="float__right" src={bb2}></img>
            <ul>
                <li>Moment privilégié</li>
                <li>Détente</li>
                <li>Enrichit le lien d’attachement</li>
                <li>Confiance en soi et de la compétence parentale</li>
                <li>Stimulation de la lactation</li>
                <li>Baisse de la dépression postnatale</li>
                <li>Partage</li>
            </ul>
            <h2>Pour Qui ?</h2>
            <p>
                Les parents ou les responsables de l’enfant et le bébé
            </p>
            <h2>Comment ?</h2>
            <p>
                A l’aide d’un programme de massage, spécialement conçu par Vimala McClure, fondatrice de
                l'International Association of Infant Massage (IAIM). Vimala a associé des techniques de massage
                indien et suédois, ainsi que quelques principes issus de la réflexologie et du yoga.
            </p>
            <h2>Déroulement d'une session de 5 ateliers</h2>
            <p>
                Les ateliers se déroulent sur 5 séances d’1h30, vous profiterez de la transmission des gestes
                selon le protocole de l’IAIM (International Association of Infant Massage) et d’un apprentissage
                progressif.
            </p>
            <p>
                Le respect de votre bébé et de son rythme est la priorité
            </p>
            <p>
                Chaque séance se compose d’un temps de relaxation, de mouvements de massage, de théorie et
                de partage
            </p>
            <h2>En pratique :</h2>
            <p>
                Des informations sur des thèmes tels que la permission, les conditions, les huiles, les états de
                conscience, etc.
            </p>
            <p>
                Partager et échanger entre nous en séance individuelle ou en séance collective avec d’autres familles
            </p>
            <h2>Les tarifs :</h2>
            <p>
                Atelier découverte : 35€<br></br>
                Session de 5 ateliers collectifs d'1h30 : 120€<br></br>
                Flacon d'huile et livret de massage offert
            </p>
        </div>
    )
}