import DBL_en from "../assets/logo DBL anglais.png"

export default function DBL_PRO_CMPNT() {
    return(
        <div className="container">
            <h1>Formation Instructeur/Instructrice en Dunstan Baby Language®</h1>
            <img className="img__middle" src={DBL_en}></img>
            <p>
                Apprendre à décoder les pleurs physiologiques des bébés avec le Dunstan Baby Language, c’est ici !
            </p>
            <p>
                Le Dunstan Baby Language est l’outil indispensable pour les parents, futurs parents et toute accompagnant les bébés au quotidien.
            </p>
            <h2>En quoi consiste le Dunstan Baby Language ?</h2>
            <p>
                Nous savons aujourd’hui, grâce à la recherche scientifique, que les bébés communiquent avec des sons très distincts. C’est leur langage naturel, ce langage, leur permet de nous dire ce dont ils ont besoin et à nous de répondre justement à ces besoins.
            </p>
            <p>
                En devenant Instructeur/Instructrice en Dunstan Baby Language, vous aurez la possibilité d’animer des ateliers de décodage des pleurs à destination des parents et des professionnels.
            </p>
            <h2>Pourquoi animer des ateliers à destination des parents ?</h2>
            <p>
                Durant les premières semaines de vie, les parents se retrouvent souvent démunis face aux pleurs de leur bébé.
            </p>
            <p>
                En leur permettant de mieux comprendre leur enfant , vous permettez aux familles de valoriser leurs compétences parentales, de développer le lien parent/enfant  et de rendre le bébé plus sécure
            </p>
            <p>
                La formation se déroule : 
            </p>
            <ul>
                <li>En présentiel sur une journée :</li>
                <ul>
                    <li>Module 1, de 9h00 à 12h00</li>
                    <li>Module 2, de 13h00 à 16h00</li>
                </ul>
                <li>En visio via Whereby soit sur une journée soit sur 2 demies journées :</li>
                <ul>
                    <li>Module 1, de 9h00 à 12h00</li>
                    <li>Module 2, de 13h00 à 16h00</li>
                </ul>
            </ul>
            <ul>La formation vous donne le droit :
                <li>De diffuser les vidéos officielles <b>UNIQUEMENT</b> en atelier</li>
                <li>D'utiliser le logo DBL</li>
                <li>D'être adhérent.e de l'association Dunstan Baby France</li>
                <li>D'être référencé.e sur l'annuaire des instructeurs sur le site <a href="https://www.dunstanbabyfrance.com/">Dunstan Baby France</a></li>
                <li>d'accéder au groupe Facebook d'instructeur/instructrices</li>
            </ul>
            <p>
                Si vous avez des questions, vous pouvez me contacter au 07 66 64 04 28 ou m'envoyer un mail à contact@bubblemome.fr
            </p>
            <iframe src="https://www.laurelanglais.fr/wp-content/uploads/2023/02/Fiche-pedagogique-dinstructeur-DBL.pdf" width="100%" height="500px"></iframe>
        </div>
    )
}