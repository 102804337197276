import { useState } from "react"

export default function Ateliers() {
    const [dropdown, setDropdown] = useState(true)
    const handleOpen = () => {
        setDropdown(!dropdown);
    }
    return (
            dropdown ? 
            <div className="dropdown"><li onClick={handleOpen}>Ateliers</li></div>: (
            <div className="dropdown"><li onClick={handleOpen}>Ateliers</li>
                <div className="absolute">
                    <li><a href="/csap">Communiquation Signée</a></li>
                    <li><a href="/dbl">Dunstan Baby Language</a></li>
                    <li><a href="/massage">Massage Bébé</a></li>
                    <li><a href="/portage">Portage Physiologique</a></li>
                    <li><a href="/rebozo">Rebozo</a></li>
                </div>
            </div> ) 
    )
}