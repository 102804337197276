import AS from "../assets/Euloge A 11198.jpg"

export default function QSJ() {
    return (
        <div className="container">
            <h1>Qui suis-je ?</h1>
            <img className="img__AS" src={AS} alt="Aurélie Savy"></img>
            <div>
                <p>
                    Bonjour!
                </p>
                <p>
                    Je suis Aurélie SAVY, maman de 3 magnifiques enfants ; Educatrice de Jeunes Enfants de
                    formation, j’exerce ce métier depuis de nombreuses années maintenant, j’accompagne les
                    professionnels de l’accueil à domicile et passionnée d’accompagnement à la périnatalité, je me suis
                    formée à divers outils afin de proposer un accompagnement juste, de qualité et correspondant aux
                    besoins des parents afin de valoriser leurs compétences.
                </p>
                <p>
                    Attachée à la Langue des Signes Française depuis ma plus tendre enfance grâce à l’émission « Mes
                    mains ont la parole », j’ai souhaité apprendre cette langue, l’allier à mon métier, et ainsi permettre
                    aux tout-petits de communiquer avant qu’ils ne sachent parler. Aujourd’hui, j’ai à cœur de
                    transmettre la Communication Signée Accompagnée de la Parole aussi bien aux parents qu’aux professionnels afin que tous puissent partager avec les
                    tout-petits ce fabuleux outil de communication.
                </p>
                <p>
                    Parce que l’on ne s’intéresse pas assez aux pleurs et plus particulièrement à ceux du tout-petit, je me
                    suis formée au Dunstan Baby Language, ou, à l’art de comprendre le langage naturel des bébés des 0 à
                    5 mois, aujourd’hui, j’ai à cœur d’accompagner les futurs parents et jeunes parents afin qu’ils
                    comprennent les pleurs physiologiques de leurs bébés de manière à répondre justement à leurs
                    besoins. Et, pour que cet accompagnement soit en lien avec l’accueil des tout-petits, je transmets cet
                    outil aux professionnels de la Petite Enfance.
                </p>
                <p>
                    Sensible aux bienfaits du massage, j’ai souhaité devenir instructrice en Massage Bébé afin que les
                    parents puissent grâce aux gestes sains et nourrissants apaiser bébé et créer un lien fort et ce dès la
                    naissance de ce dernier.
                </p>
                <p>
                    Convaincue des bienfaits du portage physiologique, j’ai décidé de me former à ce moyen de portage.
                    Ainsi, permettre aux parents de choisir l’outil de portage qui leur conviendra le mieux, est une véritable
                    source de satisfaction, écharpe, meï taï,etc. peu importe pourvu que l’on trouve celui qui satisfera
                    leurs attentes
                </p>
                <p>
                    Praticienne Rebozo, j’utilise cette écharpe d’origine mexicaine pour procurer, grâce à un ensemble de
                    techniques douces et enveloppantes un moment de bien-être et de relaxation mais pas seulement puisque
                    cette petite écharpe aux multiples bienfaits est également utilisée en portage, en soutien pendant la
                    grossesse et l’accouchement
                </p>
                <p>
                    Les accompagnements que je propose se veulent uniques car adaptés à chacun, où les valeurs de
                    respect et de non-jugement sont de mises.
                </p>
                <p>
                    C’est donc avec bonheur, que je vous accompagnerai sur l’un ou l’autre, et, pourquoi pas plusieurs
                    ateliers !
                </p>
                <p>
                    Au plaisir d’échanger ensemble,
                </p>
                <p>
                    Aurélie
                </p>
            </div>
        </div>
        
    )
}