import Logo1200 from "../assets/logo_BM_1200px 2.png"
import Logo768 from "../assets/logo_BM_600px.jpg"
import Logo576 from "../assets/logo_BM_200px.jpg"
import Hamburger from "../assets/Hamburger_icon.svg"
import Ateliers from "./Ateliers"
import Menu from "./Menu"


export default function Header() {
    return (
        <>
        <header>
            <div className="wrapper">
                
                <nav>
                <a href="/" id="logo">
                    <picture>
                        <source media="(min-width:1200px)" srcSet={Logo1200}></source>
                        <source media="(min-width:768px)" srcSet={Logo768}></source>
                        <img src={Logo576} alt="Logo" id="logo"></img>
                    </picture>
                </a>
                    <ul className="navbar__desktop">
                        <li className="align"><a href="/">Qui suis-je?</a></li>
                        <Ateliers/>
                        <li className="align"><a href="/professionnels">Professionnels</a></li>
                        <li className="align"><a href="/temoignages">Temoignages</a></li>
                        <li className="align"><a href="/confiance">Ils me font confiance</a></li>
                        <li className="align"><a href="/contact">Contact</a></li>
                    </ul>
                    <Menu/>
                </nav>
            </div>
            
        </header>
        <div className="spacer"></div>
        </>
    )
}