import rebozo1 from "../assets/rebozo 2.jpg"
import rebozo2 from "../assets/rebozo 3.jpg"

export default function Rebozo_CMPNT() {
    return (
        <div class="container">
            <h1>Le Rebozo : l'écharpe aux milles couleurs et aux mille facettes</h1>
            <h2>Les origines du Rebozo</h2>
            <img className="float__right" src={rebozo1}></img>
            <p>
                Le Rebozo (Châle en espagnol) est en réalité une écharpe traditionnelle
                tissée provenant du Mexique. Popularisé au 18è siècle, ce long châle qui
                peut atteindre plus de 2,5 m de long, est généralement fait en coton et/ou
                en soie, c’est un accessoire tour à tour populaire et précieux, dont le secret
                de tissage se transmet exclusivement de femme à femme.
            </p>
            <p>
                Parfois paré de fil d'or et d'argent, il peut être offert lors d'une demande de
                mariage. La coutume voudrait d'ailleurs que l'on puisse évaluer la finesse et
                la qualité de la soie utilisée pour le Rebozo en passant ce dernier à travers
                une bague.
            </p>
            <p>
                Au siècle suivant, le Rebozo devient un gage de féminité et d'humilité.
                Employé tour à tour pour souligner la grâce d';un mouvement ou pour se
                couvrir la tête à l'Eglise, le Rebozo est aussi l'un des symboles forts de
                l'indépendance mexicaine. En effet, au début du 20 e  siècle, il aurait été
                employé par les femmes révolutionnaires pour porter leur bébé et faire
                passer, par la même occasion, des armes au-delà des checkpoints ennemis.
            </p>
            <p>
                Si le Rebozo est aujourd'hui encore porté dans sa région d'origine, il est
                devenu, de par le monde, un accessoire accompagnant les femmes enceintes
                et jeunes mères au cours de la grossesse, pendant la naissance et en période
                post-natale.
            </p>
            <p>
                C'est pourquoi il est employé de multiple façon :
            </p>
            <ul>
                <li>
                    Au cours de la grossesse pour en apaiser les maux. Noué avec un
                    serrage de précision sur les hanches et sous le ventre, le Rebozo
                    permet de maintenir le bassin, de soutenir le ventre et d'apaiser les
                    douleurs ligamentaires ou les maux de dos très fréquents chez la
                    femme enceinte. En massage, il apaise le stress et la fatigue
                </li>
                <li>
                    Pendant le travail pour soulager certaines douleurs
                </li>
                <li>
                    En post-Natal pour accompagner la femme dans son nouveau rôle de mère
                </li>
            </ul>
            <h2>Les bienfaits du soin Rebozo ?</h2>
            <img className="float__left" src={rebozo2}></img>
            <p>
                Au-delà de la détente et de la relaxation qu&#39;il apporte, le massage et/ou soin
                Rebozo vise à :
            </p>
            <ul>
                <li>
                    Aider la jeune mère à se remettre en lien avec son schéma corporel. Après
                    les multiples changements induits par la grossesse, puis par
                    l&#39;accouchement, ce soin l&#39;aide ainsi à (re)prendre conscience des limites de
                    son corps, et métaphoriquement de ses limites en tant que parent.
                </li>
                <li>
                    Resserrer le bassin souvent relâché après la naissance. En cause :
                    l&#39;hyperlaxité des ligaments pendant la grossesse et le travail qui favorise la
                    descente du bébé à la naissance.
                </li>
                <li>
                    Détoxifier l'organisme
                </li>
            </ul>
            <p>
                Le massage : 
            </p>
            <p>
                Au cours d’un massage, le Rebozo est la continuité de nos bras, de nos
                mains pour offrir un enveloppement, une contenance à l’autre sans envahir
                son espace, en le laissant dans sa bulle, en toute sécurité
            </p>
            <p>
                Il s’adresse à tout le monde et ce dès le plus jeune âge, les personnes âgées
                peuvent aussi en bénéficier, en quelques mots, le Rebozo traverse le temps,
                les âges et offre une parenthèse de bien-être, un moment bulle à tous.
            </p>
            <p>
                Il s'avère très utile pour :
            </p>
            <ul>
                <li>
                    L’accompagnement des personnes de manière générale
                </li>
                <li>
                    La vie familiale ; un moment bulle pour les parents, futurs parents
                    mais aussi pour les enfants, les proches, les grands-parents
                </li>
                <li>
                    Une pause au travail : relâcher les tensions, se détendre
                </li>
            </ul>
            <p>
                Ainsi un bercement lent et doux du haut du corps en position assise
                procure un relâchement rapide des tensions, un véritable moment
                bulle à savourer !
            </p>
            <h2>Tarif :</h2>
            <p>
                Massage découverte de 15 à 20 minutes : 30€<br></br>
                Echange et boisson offerte
            </p>
        </div>
    )
}